export function secToTime(sec: number) {
  return new Date(1000 * sec)
    .toISOString()
    .slice(11, 19)
    .replace(/^[0:]{3}/, "")
}

export function stereoToString(stereo: number) {
  switch (stereo) {
    case -1:
      return "L----"
    case -0.5:
      return "-L---"
    case 0.0:
      return "--C--"
    case 0.5:
      return "---R-"
    case 1.0:
      return "----R"
    case undefined:
      return "-----"
  }
  return stereo.toString()
}

export function numToPercent(num: number) {
  return (num * 100).toFixed(0) + "%"
}
