import React from "react"
import NotePlayer from "../Media/NotePlayer"
import { HowlerPlayer } from "../Media/HowlerPlayer"
import slugify from "underscore.string/slugify"
import Collapsible from "react-collapsible"
import { Col } from "react-flexbox-grid"
import { SongTags } from "./SongTags"
import { SongDetailsProps } from "../../types/song"
import SheetMusicViewer from "../Media/SheetMusicViewer"
import {
  Lyrics,
  TopCreditsStyle,
  BottomCreditsStyle,
  PageHeaderWrapper,
} from "../../styles/SongDetails"

const strMelody = "melodi"
const strLyricsCredit = "text"
const strMelodyCredit = "musik"
const strShowSheet = "noter"
const strShowAudio = "inspelning"

const TopCredits = ({ melody, pre }) => (
  <TopCreditsStyle>
    {melody && (
      <>
        {strMelody}: {melody}
        <br />
      </>
    )}
    {pre && <div>{pre}</div>}
  </TopCreditsStyle>
)

const Tuning = ({ startnote }) => (
  <Col xs={12} sm={5} md={4} lg={3}>
    <NotePlayer
      className={`noselect`}
      note={startnote[0]}
      text={startnote.length > 1 ? startnote.join(", ") : "unis. " + startnote}
    />
  </Col>
)

const SheetCollapsible = ({ smallSheet, mediumSheet, largeSheet }) => (
  <Collapsible transitionTime={200} easing={"ease-in"} trigger={strShowSheet}>
    <SheetMusicViewer
      smallUrl={smallSheet}
      mediumUrl={mediumSheet}
      largeUrl={largeSheet}
    ></SheetMusicViewer>
  </Collapsible>
)

const BottomCredits = ({ melodyCredit, lyricsCredit }) => (
  <BottomCreditsStyle>
    {melodyCredit && (
      <>
        {strMelodyCredit}: {melodyCredit}
        <br />
      </>
    )}
    {lyricsCredit && (
      <>
        {strLyricsCredit}: {lyricsCredit}
        <br />
      </>
    )}
  </BottomCreditsStyle>
)

const SongDetails: React.FunctionComponent<SongDetailsProps> = ({
  title,
  lyrics,
  lyricsCredit,
  melody,
  melodyCredit,
  pre,
  startnote,
  largeSheet,
  mediumSheet,
  smallSheet,
  audio,
  tag,
  sheetOnly,
}) => {
  const hasSheets = smallSheet && mediumSheet && largeSheet

  return (
    <>
      <PageHeaderWrapper>
        <Col xs={12} sm md lg>
          <h1>{title}</h1>
          <SongTags tag={tag} />
          <TopCredits melody={melody} pre={pre} />
        </Col>
        {startnote && <Tuning startnote={startnote} />}
      </PageHeaderWrapper>
      {hasSheets && !sheetOnly && (
        <SheetCollapsible
          smallSheet={smallSheet}
          mediumSheet={mediumSheet}
          largeSheet={largeSheet}
        />
      )}
      {audio &&
        audio.map((singleAudio) => (
          <HowlerPlayer
            audio={singleAudio}
            key={`audio-${slugify(singleAudio.name)}`}
          />
        ))}
      {!sheetOnly || (sheetOnly && !hasSheets) ? (
        <Lyrics dangerouslySetInnerHTML={{ __html: lyrics }} />
      ) : (
        <SheetMusicViewer
          smallUrl={smallSheet}
          mediumUrl={mediumSheet}
          largeUrl={largeSheet}
        ></SheetMusicViewer>
      )}
      <BottomCredits melodyCredit={melodyCredit} lyricsCredit={lyricsCredit} />
    </>
  )
}

export default SongDetails
