import React from "react"
import styled from "styled-components"
import { Col } from "react-flexbox-grid"
import { Button } from "./Button"
import { Range, Direction, getTrackBackground } from "react-range"

export const LabelCol = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  width: 4em;
`
export const TitleCol = styled(Col)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  margin-bottom: 0.5em;
`
export const PlayerButton = styled(Button)`
  @media (max-width: 375px) {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  @media (min-width: 376px) {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  padding: 0.4375em 1em 0em 1em;
  cursor: pointer;
`

// colours
const upperColor = "var(--neutral10)"
const lowerColor = "var(--primary)"
const upperMutedColor = "var(--neutral20)"
const lowerMutedColor = "var(--neutral30)"
const thumbColor = "var(--fg)"
const thumbHoverColor = "var(--neutral50)"

// "height" and "width" correspond to horizontal sliders. These values will be
// swapped for horizontal sliders.
const trackHeight = "1em"
const thumbHeight = "3.25em"
const thumbPadding = "0.75em"
const thumbWidth = "2.25em"
// Actual height of thumb == 3.25em - 2*0.75em
//
export const InputSliderWrapper = styled.div<{ inputValueType: string }>`
  ${(props) =>
    props.inputValueType === "stereo"
      ? "padding: 0.5em 1em 0.5em 1em;"
      : "padding: 0.5em 1em 0.5em 1em;"}
`
export const StyledInputSlider = styled(Range)``

export const StyledTrack = styled.div<{
  inputValueType?: string
  direction?: Direction
  muted?: boolean
  min: number
  max: number
  step: number
  values: number[]
  fillTrack: boolean
}>`
  ${(props) => {
    return props.direction === "to top"
      ? `
        height: 10em;
        width: ${trackHeight};
      `
      : `
        height: ${trackHeight};
        width: 100%;
      `
  }}
  background: ${(props) => {
    if (props?.muted && props.fillTrack) {
      return getTrackBackground({
        values: props.values,
        colors: [lowerMutedColor, upperMutedColor],
        min: props.min,
        max: props.max,
        rtl: false,
        direction: props.direction,
      })
    } else if (props.fillTrack) {
      return getTrackBackground({
        values: props.values,
        colors: [lowerColor, upperColor],
        min: props.min,
        max: props.max,
        rtl: false,
        direction: props.direction,
      })
    } else {
      return lowerColor
    }
  }};
  display: inline-block;
`

export const StyledThumb = styled.div<{ direction?: Direction }>`
  ${(props) => {
    return props.direction === "to top"
      ? `
        height: ${thumbWidth};
        width: ${thumbHeight};
        padding: ${thumbPadding};
      `
      : `height: ${thumbHeight};
      width: ${thumbWidth};
      padding: ${thumbPadding};
    `
  }}
  background-color: ${thumbColor};

  &:hover {
    background-color: ${thumbHoverColor};
  }
  background-clip: content-box;
`

export const StyledMarks = styled.div<{
  step: number
  index: number
  values: number[]
}>`
  height: 1.75em;
  width: 0.25em;
  background-color: var(--primary);
`
