import React, { useEffect, useState, useRef } from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

const Img = styled.img`
  width: 100%;
  max-width: max-content;
  margin: 0;
  filter: var(--fgFilter);
`

interface SheetMusicViewerProps {
  smallUrl: string
  mediumUrl: string
  largeUrl: string
}

const SheetMusicViewer = ({
  largeUrl,
  mediumUrl,
  smallUrl,
}: SheetMusicViewerProps) => {
  const [size, setSize] = useState("large")
  const imgRef = useRef<HTMLImageElement>(null)
  const breakpoints = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            breakpoints {
              sm
              md
            }
          }
        }
      }
    `
  ).site.siteMetadata.breakpoints

  // Listen for window resizes
  useEffect(() => {
    const resizeListener = () => {
      if (imgRef.current !== null) {
        const width = imgRef.current.parentElement.clientWidth
        if (width <= breakpoints.sm) {
          setSize("small")
        } else if (width <= breakpoints.md) {
          setSize("medium")
        } else {
          setSize("large")
        }
      }
    }
    // Set correct initial size
    resizeListener()
    window.addEventListener("resize", resizeListener)

    return () => window.removeEventListener("resize", resizeListener)
  }, [])

  let sheetUrl: string
  switch (size) {
    case "large":
      sheetUrl = largeUrl
      break
    case "medium":
      sheetUrl = mediumUrl
      break
    case "small":
      sheetUrl = smallUrl
      break
  }

  return (
    <Img className="sheet" src={sheetUrl} alt={`Sheet music`} ref={imgRef} />
  )
}

export default SheetMusicViewer
