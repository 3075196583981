import * as React from "react"
import SEO from "../components/Layout/SEO"
import { PageHeaderWrapper } from "../styles/SongDetails"
import { Col } from "react-flexbox-grid"
const NotFoundPage: React.FunctionComponent = () => (
  <>
    <SEO description="404: Not found" />
    <PageHeaderWrapper>
      <Col xs={12}>
        <h1>404: Sidan kunde inte hittas</h1>
        <p>
          Av orsak eller annan kan sidan du letar efter inte hittas. Förslagsvis
          kan du använda sökfältet ovan för att leta vidare!
        </p>
      </Col>
    </PageHeaderWrapper>
  </>
)

export default NotFoundPage
