import React, { useState, useEffect, useLayoutEffect } from "react"
import { Howl, Howler, SoundSpriteDefinitions } from "howler"
import { Col, Row } from "react-flexbox-grid"
import { Seq } from "immutable"
import Collapsible from "react-collapsible"
import { Play, Pause } from "react-feather"

import { InputSlider } from "./InputSlider"
import { SongAudio } from "../../types/song"
import { TitleCol } from "../../styles/HowlerPlayer"
import { playerButtonProps } from "../../styles/FeatherIcon"
import { PlayerButton } from "../../styles/HowlerPlayer"
import { Direction } from "react-range"
import { secToTime } from "../../utils/howlerPlayerUtils"
interface HowlerPlayerProps {
  audio: SongAudio
}
export const HowlerPlayer = (props: HowlerPlayerProps) => {
  const audioFile = props.audio.file.publicURL

  const [isPlaying, setIsPlaying] = useState<boolean>(false)
  const [track, setTrack] = useState<Howl>(null)
  const [soundId, setSoundId] = useState<[number, string][]>([])
  const [duration, setDuration] = useState<number>(0)
  const [seek, setSeek] = useState<number>(0.0)
  const [finalSeek, setFinalSeek] = useState<number>(0.0)
  const [isSeeking, setIsSeeking] = useState<boolean>(false)

  const sprites: SoundSpriteDefinitions = props.audio.voices
    ? props.audio.voices.reduce((previousVoice, currentVoice) => {
        let combined = previousVoice
        combined[currentVoice.name] = [
          currentVoice.startTime,
          currentVoice.duration,
        ]
        return combined
      }, {})
    : null

  // Handle seeking
  useEffect(() => {
    if (track && !isSeeking) {
      if (soundId.length !== 0) {
        soundId.forEach((sound) => {
          const offset = sprites[sound[1]][0] / 1000
          track.seek(seek + offset, sound[0])
        })
      } else {
        track.seek(seek)
      }
    }
  }, [finalSeek])

  useEffect(() => {
    // Create new Howl from file
    const newTrack = new Howl({
      src: [audioFile],
      sprite: sprites || null,
      preload: true,
      autoplay: false,
      onend: () => {
        // NOTE: Avoid setting state once per sprite somehow? Unneccessary and
        // will cause issues if sprites are of different length
        setIsPlaying(false)
      },
    })
    setTrack(newTrack)

    // Create IDs for each sprite by playing and stopping
    // TODO: Could perhaps be done more smoothly some other way
    let currentIds: [number, string][] = []
    if (sprites) {
      Object.keys(sprites || {}).forEach((sprite) => {
        const id = newTrack.play(sprite)
        newTrack.stop(id)
        currentIds.push([id, sprite])
      })
      setSoundId(currentIds)
    }

    handleSetDuration()
    // When component umounts, unload the sound file
    return () => {
      newTrack.unload()
    }
  }, [])

  useEffect(() => {
    handleSetDuration()
  }, [track?.state()])

  function handleSetDuration() {
    // Set duration to duration of the largest sprite
    setDuration(
      sprites
        ? Math.max(
            ...Seq(sprites)
              .toList()
              .map((sprite: any) => {
                return sprite[1] - sprite[0]
              })
          ) / 1000
        : track?.duration() || 0
    )
  }

  // Handle animation for progress bar
  useLayoutEffect(() => {
    if (isPlaying && !isSeeking) {
      let timerId: number
      const f = () => {
        setSeek(track?.seek())
        timerId = requestAnimationFrame(f)
      }
      timerId = requestAnimationFrame(f)
      return () => cancelAnimationFrame(timerId)
    }
  }, [isSeeking, isPlaying])

  function handleStop() {
    track && track.stop()
    setSeek(0)
    setIsPlaying(false)
  }

  function handleToggle() {
    if (track?.playing()) {
      if (sprites) {
        soundId.forEach((id) => {
          track.pause(id[0])
        })
      } else {
        track.pause()
      }
    } else {
      if (sprites && soundId.length === 0) {
        let currentIds = []
        Object.keys(sprites).forEach((sprite) => {
          const id = track.play(sprite)
          currentIds.push(id)
        })
        setSoundId(currentIds)
      } else if (sprites) {
        soundId.forEach((id) => {
          track.play(id[0])
        })
      } else {
        track.play()
      }
    }
    setIsPlaying(!isPlaying)
  }

  const volumeBarProps = {
    xs: 6,
    sm: 3,
    md: 2,
    lg: 2,
    xl: 2,
  }
  return (
    <Collapsible
      transitionTime={100}
      easing={"ease"}
      trigger={props.audio.name}
      onClosing={handleStop}
    >
      <Row middle="xs">
        <Col xs>
          <InputSlider
            contentLeft={secToTime(seek)}
            contentRight={secToTime(duration)}
            inputValueType="seek"
            name={"song-controls"}
            min={0}
            max={duration ? duration : 100}
            defaultValue={0}
            step={1.0}
            direction={Direction.Right}
            fillTrack={false}
            onChange={(values: number[]) => {
              setSeek(values[0])
              setIsSeeking(true)
            }}
            onFinalChange={(values: number[]) => {
              setFinalSeek(values[0])
              setIsSeeking(false)
            }}
            values={[seek]}
            track={track}
          />
        </Col>
        <Col xs={12} sm={2} md={2} lg={3}>
          <PlayerButton onClick={handleToggle}>
            {isPlaying ? (
              <Pause {...playerButtonProps} />
            ) : (
              <Play {...playerButtonProps} />
            )}
          </PlayerButton>
        </Col>
      </Row>
      <Row around="xs">
        {soundId.length >= 2 && (
          <Col {...volumeBarProps} key={`controls-main`}>
            <Row center="xs">
              <TitleCol xs={12}>
                <b>{"Huvudvolym"}</b>
              </TitleCol>
              <Col xs>
                <InputSlider
                  name={`Huvudvolym`}
                  track={track}
                  inputValueType="volume"
                  min={0}
                  max={1}
                  defaultValue={1}
                  step={0.05}
                  direction={Direction.Up}
                  fillTrack={true}
                  onChange={(num: number[]) => {
                    if (track) {
                      Howler.volume(num[0])
                    }
                  }}
                />
              </Col>
            </Row>
          </Col>
        )}
        {soundId.length >= 2 &&
          soundId.map((id) => {
            return (
              <Col {...volumeBarProps} key={`controls-${id[0]}`}>
                <Row center="xs">
                  <TitleCol xs={12}>
                    <b>{id[1]}</b>
                  </TitleCol>
                  <Col xs={12}>
                    <InputSlider
                      soundId={id}
                      name={`${id[1]} volym`}
                      track={track}
                      inputValueType="volume"
                      min={0}
                      max={1}
                      defaultValue={1}
                      step={0.05}
                      direction={Direction.Up}
                      fillTrack={true}
                      onChange={(num: number[]) => {
                        if (track) {
                          track.volume(num[0], id[0])
                        }
                      }}
                    />
                  </Col>
                  <Col xs={12}>
                    <InputSlider
                      name={`${id[1]} balans`}
                      track={track}
                      inputValueType="stereo"
                      min={-1.0}
                      max={1.0}
                      defaultValue={0}
                      direction={Direction.Right}
                      step={0.5}
                      useMarks={true}
                      fillTrack={false}
                      onChange={(num: number[]) => {
                        if (track) {
                          track.stereo(num[0], id[0])
                        }
                      }}
                      key={`stereo-${id[0]}`}
                      disabled={!Howler.usingWebAudio}
                    />
                  </Col>
                </Row>
              </Col>
            )
          })}
      </Row>
    </Collapsible>
  )
}
export default HowlerPlayer
