import React from "react"
import styled from "styled-components"
import { globalTransition } from "./Style"
import { Row } from "react-flexbox-grid"

/** Styled components for different text elements. */
export const Lyrics = styled.div`
  border-top: 1px solid;
  padding-top: 1rem;
  width: 100%;

  & h1 {
    display: none;
  }

  & p {
    white-space: pre-wrap;
  }

  & img {
    filter: var(--fgFilter);
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 40em;
    width: 80vw;
  }

  & blockquote {
    margin: 0 0 1rem;
    border-bottom: 1px solid var(--fg);
    padding: 0 1.4rem calc(1.4rem - 1px);
    font-style: italic;

    & > p {
      white-space: normal;
    }
  }

  & p + blockquote {
    margin: 0;
    border-top: 1px solid var(--fg);
    border-bottom: none;
    padding: calc(1.4rem - 1px) 1.4rem 1.4rem;
    transition: border-top ${globalTransition};
  }

  /* Note: this must be a span with display: block
   * as remark messes up the paragraphs if it is a div.
   */
  & .directive-main {
    display: block;
  }

  & p .directive-main {
    display: inline;
  }

  & .directive-wrapper {
    display: inline-block;
  }

  & .directive-left {
    display: inline-block;
    vertical-align: top;
    padding-right: 0.5em;
  }

  & .directive-right {
    display: inline-block;
    padding-left: 0.5em;
  }

  & .directive-center {
    display: inline-block;
    text-align: center;
  }

  //& .Collapsible__trigger {
  //transition: ${globalTransition};
  //}
`
export const PageHeaderWrapper = styled(Row)`
  margin-top: 2em;
`
export const TopCreditsStyle = styled.div`
  font-weight: 300;
  font-style: italic;
  margin: 0.5em 0;

  /* div for pre, note margin collapse */
  & > div {
    margin-top: 0.5em;
  }
`
export const BottomCreditsStyle = styled.div`
  font-weight: 300;
  font-style: italic;
  padding-left: 1.4rem;
`
