import * as React from "react"
import { graphql } from "gatsby"
import { Song } from "../types/song"
import SongDetails from "../components/Song/SongDetails"
import NotFoundPage from "../pages/404"

interface SongTemplateProps {
  pageContext: {
    songSlug: string
    bookSlug: string
  }

  data: {
    songQueryResult: Song
    siteQuery: any
  }
}

/**
 * Template used by each song details page
 */
const SongTemplate: React.FunctionComponent<SongTemplateProps> = ({
  data: { songQueryResult: song, siteQuery: site },
}) => {
  return song ? (
    <SongDetails
      title={song.title}
      lyrics={song.html}
      largeSheet={song.largeSheet && song.largeSheet.publicURL}
      mediumSheet={song.mediumSheet && song.mediumSheet.publicURL}
      smallSheet={song.smallSheet && song.smallSheet.publicURL}
      tag={song.tag}
      audio={song.audio}
      sheetOnly={site.siteMetadata.sheetOnly}
      {...song.frontmatter}
    />
  ) : (
    <NotFoundPage />
  )
}

export const songPageQuery = graphql`
  query ($songSlug: String!, $bookSlug: String!) {
    songQueryResult: markdownRemark(
      slug: { eq: $songSlug }
      book: { slug: { eq: $bookSlug } }
    ) {
      slug
      title
      excerpt
      book {
        title
        slug
      }
      largeSheet {
        publicURL
      }
      mediumSheet {
        publicURL
      }
      smallSheet {
        publicURL
      }
      tag {
        tag
        value
        label
        color
      }
      html
      audio {
        name
        file {
          publicURL
        }
        voices {
          name
          startTime
          duration
        }
      }
      frontmatter {
        page
        startnote
        lyricsCredit
        melody
        melodyCredit
        type
        musicalKey
        songOrder
        pre
      }
    }
    siteQuery: site {
      siteMetadata {
        sheetOnly
      }
    }
  }
`

export default SongTemplate
