import React, { useState, FC } from "react"
import { Row, Col } from "react-flexbox-grid"
import {
  StyledInputSlider,
  StyledTrack,
  StyledThumb,
  StyledMarks,
  LabelCol,
  InputSliderWrapper,
  PlayerButton,
} from "../../styles/HowlerPlayer"
import { Direction } from "react-range"
import { VolumeX, Volume, Volume1, Volume2 } from "react-feather"
import { playerButtonProps } from "../../styles/FeatherIcon"
import styled from "styled-components"
import { Howl } from "howler"

const VolumeIndicator = ({ currentVolume }) => {
  if (currentVolume === null) {
    return <VolumeX {...playerButtonProps} />
  } else if (currentVolume <= 0.25) {
    return <Volume {...playerButtonProps} />
  } else if (currentVolume <= 0.75) {
    return <Volume1 {...playerButtonProps} />
  } else {
    return <Volume2 {...playerButtonProps} />
  }
}

const VolumeIndicatorWrapper = styled.div`
  margin-top: 0.5em;
`

interface InputSliderProps {
  useMarks?: boolean
  min: number
  max: number
  step: number
  defaultValue: number
  inputValueType: string
  track: Howl
  name: string
  vertical?: boolean
  contentLeft?: string
  contentRight?: string
  direction?: Direction
  onChange: (num: number[]) => void
  onFinalChange?: (num: number[]) => void
  onBeforeChange?: (num: number[]) => void
  onAfterChange?: (num: number[]) => void
  values?: number[]
  disabled?: boolean
  soundId?: [number, string]
  fillTrack: boolean
}

export const InputSlider: FC<InputSliderProps> = (props: InputSliderProps) => {
  const [values, setValues] = useState<number[]>([props.defaultValue as number])
  const [muted, setMuted] = useState<boolean>(false)

  const renderMark = ({ props, index }) => (
    <StyledMarks
      {...props}
      values={props.values || values}
      step={props.step}
      index={index}
    />
  )

  function handleMuteToggle(): void {
    if (!!props.track) {
      if (props?.soundId) {
        props.track.mute(!muted, props?.soundId[0])
      } else {
        props.track.mute(!props.track.mute())
      }
      setMuted(!muted)
    }
  }

  const parentProps = props

  return (
    <InputSliderWrapper inputValueType={parentProps?.inputValueType}>
      <Row middle="xs">
        {props?.contentLeft && <LabelCol>{props.contentLeft}</LabelCol>}
        <Col xs>
          <StyledInputSlider
            {...props}
            onChange={(sliderValues: number[]) => {
              if (!!props.track) {
                setValues(sliderValues)
                props.onChange(sliderValues)
              }
            }}
            values={props.values || values}
            renderMark={props.useMarks ? renderMark : null}
            renderTrack={({ props, children }) => {
              return (
                <StyledTrack
                  {...props}
                  direction={parentProps?.direction}
                  inputValueType={parentProps?.inputValueType}
                  muted={muted}
                  min={parentProps.min}
                  max={parentProps.max}
                  step={parentProps.step}
                  values={values}
                  fillTrack={parentProps.fillTrack}
                >
                  {children}
                </StyledTrack>
              )
            }}
            renderThumb={({ props }) => (
              <StyledThumb {...props} direction={parentProps.direction} />
            )}
          />
        </Col>
        {props.inputValueType && props.inputValueType === "volume" && (
          <Col xs={12}>
            <VolumeIndicatorWrapper>
              <PlayerButton onClick={() => handleMuteToggle()}>
                <VolumeIndicator currentVolume={muted ? null : values[0]} />
              </PlayerButton>
            </VolumeIndicatorWrapper>
          </Col>
        )}
        {props.inputValueType && props.inputValueType === "stereo" && (
          <Col xs={12}>
            <Row between="xs">
              <Col xs={4} style={{ textAlign: "left", marginTop: "0.5em" }}>
                {"L"}
              </Col>
              <Col xs={4} style={{ textAlign: "center", marginTop: "0.5em" }}>
                {"C"}
              </Col>
              <Col xs={4} style={{ textAlign: "right", marginTop: "0.5em" }}>
                {"R"}
              </Col>
            </Row>
          </Col>
        )}
        {props?.contentRight && <LabelCol>{props.contentRight}</LabelCol>}
      </Row>
    </InputSliderWrapper>
  )
}
